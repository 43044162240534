<template>
  <div id="lengend-wrapper">
    <v-tooltip left>
      <template  v-slot:activator="{ on }">
        <v-card
          v-on="on"
          id="legend"
          class="py-1 px-1"
          color="rgba(0, 0, 0, .3)"
          dark
          flat
          link
          min-width="80"
          style="position: fixed; top: 355px; right: -35px; border-radius: 25px; z-index: 1300;"
        >
          <v-icon large>
            mdi-map
          </v-icon>
        </v-card>
      </template>
      <span>{{ $t("front.legendTooltip")}}</span>
    </v-tooltip>


    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#legend"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
      z-index=1300
    >
      <v-card
        class="text-center mb-0"
        width="400"
        style="z-index:1300;"
      >
        <v-card-text>
          <strong class="mb-3 d-inline-block">{{ $t("legend.title") }}</strong>




          <!-- <v-divider class="my-4 secondary" /> -->

            <!-- FIXME: cannot loop due to the translation issues -->
            <!-- <v-list>
              <template v-for="(icon, i) in icons" >
                <v-list-item :key="i" dense>
                  <v-list-item-icon>
                    <v-img
                    :src="icon.icon"
                    height="35"
                    contain
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ icon.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="my-4 secondary" :key="`div-${i}`"/>
              </template>
            </v-list> -->

            <v-list>
              <!-- <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/markers/marker_general.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("legend.generalmarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-4 secondary" />

              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/markers/marker_general.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("legend.generalmarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-4 secondary" />

              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/markers/marker_general.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("legend.generalmarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-4 secondary" />

              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/markers/marker_general.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("legend.generalmarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-4 secondary" />

              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/markers/marker_general.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("legend.generalmarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-4 secondary" />

              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/markers/marker_general.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("legend.generalmarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->


              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/legend/marker.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ $t("legend.generalmarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <br/>

              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/legend/dogunit.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ $t("legend.antipoisondogmarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-4 secondary" />

              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/legend/bigbird.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ $t("legend.birdmarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-4 secondary" />

              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/legend/dog.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ $t("legend.dogmarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-4 secondary" />

              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/legend/carnivores.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ $t("legend.carnivoresmarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-4 secondary" />

              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/legend/other.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ $t("legend.othermarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-4 secondary" />

              <v-list-item dense>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/legend/multiple.png')" height="35" contain />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">{{ $t("legend.multiplemarker")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'

  export default {
    name: 'LegendInfo',

    mixins: [Proxyable],

    data: function () {
      return {
        menu: false,
        saveImage: '',
      }
    },
  }
</script>

// <style lang="sass">
//   .v-settings
//     .v-item-group > *
//       cursor: pointer
        
//     &__item
//       border-width: 3px
//       border-style: solid
//       border-color: transparent !important

//       &--active
//         border-color: #00cae3 !important

//   .v-tooltip__content
//     z-index: 9999
// </style>

<style scoped>
.v-tooltip__content {
  font-size: 15px !important;
  opacity: 1 !important;
  z-index: 1400 !important;
  /* display: block !important; */
}
</style>
